<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            readonly
          />
        </b-col>
        <b-col md="4">
          <!-- date  -->
          <g-picker
            :value.sync="selectedItem.transactionDate"
            :disabled="!currentBranch.setDefaultDate"
            label-text="date"
          />
        </b-col>
        <b-col md="4">
          <!-- depositNumber  -->
          <g-field
            id="depositNumber"
            :value.sync="selectedItem.depositNumber"
            label-text="depositNumber"
            rules="required"
            name="depositNumber"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <!-- depositerName  -->
          <g-field
            id="depositerName"
            :value.sync="selectedItem.depositerName"
            label-text="depositerName"
            rules="required"
            name="depositerName"
          />
        </b-col>
        <b-col md="4">
          <g-field
            field="number"
            rules="required"
            :value.sync="selectedItem.value"
            type="number"
            label-text="value"
          />
        </b-col>
        <!-- bank  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.bankId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="bankName"
              field="select"
              name="bank1"
              :options="Banks"
              rules="required"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="mx-1"
            type="submit"
            data-action-type="save"
            variant="primary"
          >
            {{ $t('save') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            :disabled="!selectedItem.value"
            class="mx-1"
            variant="primary"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            {{ $t('saveAndPrint') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- </b-row> -->
    </g-form>
  </b-card>
</template>

<script>
import { paymentWays } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      selectedItem: {
        transactionDate: '',
        voucherType: '',
      },
      Banks: [],
      paymentMethods: [],
      transactionDate: this.today,
      chequeDate: this.today,
      paymentWays: paymentWays,
      pathName: this.$route.name,
    };
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    this.loadObj();
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    loadObj() {
      this.getBanks();
    },
    getBanks() {
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
    },
    getData() {
        this.get({
          url: 'BankDeposits',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
        })
    },
    save(type) {
      this.selectedItem.branchId = this.branchId;
        if (this.id > 0) {
          this.update({
            url: 'BankDeposits',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('updatedSuccessfully') });
              if (type === 'saveAndPrint') this.print(this.selectedItem.id);
              if (type === 'cancel') {
                window.location.reload();
              } else {
                this.$router.push({ name: 'bankDeposit-transactions' });
              }
            });
        } else {
          this.create({
            url: 'BankDeposits',
            data: this.selectedItem,
          })
            .then((data) => {
              this.doneAlert({
                text: this.$t('savedSuccessfullyWithCode', {
                  code: data.code,
                }),
              });
              if (type === 'saveAndPrint') this.print(data.id);
              this.$router.push({ name: 'bankDeposit-transactions' });
            });
        }
    },

    print(depositeId) {
      const reportName = 'BankDeposite-ar';
      const printedItem = {
        id: depositeId,
      }
      this.printReport(reportName, printedItem);
    },
  },
};
</script>

<style></style>
